import React from 'react';

import { Layout, Link } from '@components';

interface ArchiveProps {
    pageContext: {
        locale: string;
    };
}

const Archive: React.FC<ArchiveProps> = ({ pageContext }) => {
    const { locale } = pageContext;
    const indices = [...Array(23).keys()];

    return (
        <Layout lang={locale} path={'/archive'}>
            <h1 className="font-bold text-center mt-28 text-6xl md:text-7xl lg:text-8xl">
                {locale == 'en' ? 'Archive' : 'Archiv'}
            </h1>

            <div className="flex flex-wrap justify-center mb-10">
                {indices.reverse().map(i => {
                    i = i + 1;
                    return (
                        <Link
                            key={i}
                            to={'/history/' + i + '/'}
                            className="w-40 p-12 text-5xl text-center">
                            <h2>{i}</h2>
                        </Link>
                    );
                })}
            </div>
        </Layout>
    );
};

export default Archive;
